import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ProjectLinks from './ProjectLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Contact extends Component {
    render() {
        return (
            <div>
                <ProjectLinks loggedin={this.props.loggedin}/>
                <Row className="mt-3">
                    <Col sm="6">
                        <a className="contact-links" href="tel:0422413834" rel="noopener noreferrer" target="_blank" alt="RBS Contact Number">
                        <div className="box-part text-center">
                        <FontAwesomeIcon icon={faMobileAlt} size="3x" className="text-primary" />
                        <div className="mt-3">
                            <h4>Call Us</h4>
                            <h5 className="mt-3">0422413834</h5>
                        </div>
                        </div>
                        </a>
                    </Col>
                    <Col sm="6">
                        <a className="contact-links" href="mailto:jason@retailbs.com.au" alt="RBS Email">
                        <div className="box-part text-center">
                            <FontAwesomeIcon icon={faEnvelope} size="3x" className="text-danger" />
                            <div className="mt-3">
                            <h4>Email</h4>
                            <h5 className="mt-3">jason@retailbs.com.au</h5>
                            </div>
                        </div>
                        </a>
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                            <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.9222818876297!2d144.72571441637425!3d-37.58044772850371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6f9b9ad7959c5%3A0x3115b744a69dd8df!2s80+Evans+St%2C+Sunbury+VIC+3429!5e0!3m2!1sen!2sau!4v1548810936390" width="100%" height="600" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
                        </Col>
                    </Row>
            </div>
        );
    }
}

export default Contact;
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ProjectLinks from './ProjectLinks';

class NotFound extends Component {
    render() {
        return (
            <div>
                <ProjectLinks loggedin={this.props.loggedin} />
                <Row className="text-center services-panel mt-3 text-dark">
                    <Col md="12" className="mb-3">
                        <Row>
                            <Col md="12" className="pt-3">
                            <h5>Page Not Found</h5>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default NotFound;
import React, { Component } from 'react';
import ProjectLinks from './ProjectLinks';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer, faWrench, faDesktop, faNetworkWired, faEnvelopeOpen, faHdd, faShieldAlt, faPhone } from '@fortawesome/free-solid-svg-icons'

class IT extends Component {
    render() {
        return (
            <div>
                <ProjectLinks loggedin={this.props.loggedin} />
                <Row className="text-center mt-2 mb-5">
                    <Col md="12" className="mt-5 mb-3">
                        <h1 className="text-danger services-h1">IT Services</h1>
                        <h4 className="services-h3 mt-3">Service not listed below?<br /><br />Contact us on 0422 413 834.</h4>
                    </Col>
                    <Col md="12" className="mb-5 pb-5 text-center services-panel text-dark">
                        <Row>
                            <Col md="3" className="pl-5 pr-5 pt-5">
                                <FontAwesomeIcon className="text-danger fa-3x" icon={faServer} />
                                <h3 className="mt-3">IT Infrastructure (Set Up)</h3>
                            </Col>
                            <Col md="3" className="pl-5 pr-5 pt-5">
                                <FontAwesomeIcon className="text-danger fa-3x" icon={faWrench} />
                                <h3 className="mt-3">PC Repairs &amp; Upgrades (Equipment)</h3>
                            </Col>
                            <Col md="3" className="pl-5 pr-5 pt-5">
                                <FontAwesomeIcon className="text-danger fa-3x" icon={faDesktop} />
                                <h3 className="mt-3">Custom Built PC's</h3>
                            </Col>
                            <Col md="3" className="pl-5 pr-5 pt-5">
                                <FontAwesomeIcon className="text-danger fa-3x" icon={faNetworkWired} />
                                <h3 className="mt-3">Internet &amp; Network Setup</h3>
                            </Col>
                            <Col md="3" className="pl-5 pr-5 pt-5">
                                <FontAwesomeIcon className="text-danger fa-3x" icon={faEnvelopeOpen} />
                                <h3 className="mt-3">Web &amp; E-mail Hosting Services</h3>
                            </Col>
                            <Col md="3" className="pl-5 pr-5 pt-5">
                                <FontAwesomeIcon className="text-danger fa-3x" icon={faShieldAlt} />
                                <h3 className="mt-3">Virus &amp; Malware Removal (Security)</h3>
                            </Col>
                            <Col md="3" className="pl-5 pr-5 pt-5">
                                <FontAwesomeIcon className="text-danger fa-3x" icon={faHdd} />
                                <h3 className="mt-3">Data Backup &amp; Recovery</h3>
                            </Col>
                            <Col md="3" className="pl-5 pr-5 pt-5">
                                <FontAwesomeIcon className="text-danger fa-3x" icon={faPhone} />
                                <h3 className="mt-3">Small Business IT Support</h3>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default IT;
import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col, Spinner } from 'reactstrap';

class ClientIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applicantdetails: [],
            age: ""
        }
    }
    componentDidMount(){
        axios.post("https://api.groceryandliquor.co/applicants/getdetails",{retailbs: "yes", applicantid: this.props.location.applicantid})
        .then(res => {
            this.setState({
                applicantdetails: res.data[0]
            })
        })
        .catch(err => {
            //error checking
        })
    }
    formatDate (input) {
        var datePart = input.match(/\d+/g),
        year = datePart[0], // get only two digits
        month = datePart[1], day = datePart[2];
      
        return day+'/'+month+'/'+year;
      }
    render() {
        if(this.state.applicantdetails.length === 0){
            return(<div><Spinner color="light" /> Loading Applicant Details!</div>);
        } else {
            let dets = this.state.applicantdetails
            let age
            let dob
            let phones
            let mobile
            let homephone
            let study
            let workerscomp
            let qual1
            let qual2
            let qual3
            let ref1
            let ref2
            let emp1
            let emp2
            let emp3
            let crim
            let other
            let resume
            let coverletter
            if(dets.dob !== '1900-01-01'){
                age = "<strong>Current Age: </strong>"+Math.floor((new Date() - new Date(dets.dob).getTime()) / 3.15576e+10)+"<br /><br />"
                dob = "<strong>Date of Birth: </strong>"+dets.dob+"<br />"
            } else {
                dob = "<strong>Date of Birth: </strong>N/A<br /><br />"
            }
            if(dets.mobilephone && dets.homephone){
                phones = "<strong>Mobile: </strong>"+dets.mobilephone+"<br /><strong>Home Phone: </strong>"+dets.homephone+"<br /><br />"
            }
            if(dets.mobilephone && !dets.homephone){
                mobile = "<strong>Mobile: </strong>"+dets.mobilephone+"<br /><br />"
            }
            if(!dets.mobilephone && dets.homephone){
                homephone = "<strong>Home Phone: </strong>"+dets.homephone+"<br /><br />"
            }
            if(dets.studyselect==="0"){
                study = "<strong>Studing: </strong>No<br /><br />"
            } else {
                study = "<strong>Studing: </strong>Yes<br /><strong>Course Type: </strong>"+dets.coursetype+"<br /><strong>Course Name: </strong>"+dets.coursename+"<br /><br />"
            }
            if(dets.workerscompselect==="0"){
                workerscomp = "<strong>Claimed Workers Compenstation: </strong>No<br /><br />"
            } else {
                workerscomp = "<strong>Claimed Workers Compenstation: </strong>Yes<br /><strong>Compenstation Reason: </strong>"+dets.workerscompreason+"<br /><br />"
            }
            if(dets.qualification1 || dets.qualification1ints || dets.qualification1year !=="0"){
                qual1 = "<strong>Qualification: </strong>"+dets.qualification1+"<br /><strong>Completed: </strong>"+dets.qualification1year+" @ "+dets.qualification1ints+"<br /><br />"
            }
            if(dets.qualification2 || dets.qualification2ints || dets.qualification2year !=="0"){
                qual2 = "<strong>Qualification: </strong>"+dets.qualification2+"<br /><strong>Completed: </strong>"+dets.qualification2year+" @ "+dets.qualification2ints+"<br /><br />"
            }
            if(dets.qualification3 || dets.qualification3ints || dets.qualification3year !=="0"){
                qual3 = "<strong>Qualification: </strong>"+dets.qualification3+"<br /><strong>Completed: </strong>"+dets.qualification3year+" @ "+dets.qualification3ints+"<br /><br />"
            }
            if(dets.reference1 || dets.reference1position || dets.reference1number !=="0"){
                ref1 = "<strong>Reference: </strong>"+dets.reference1+" (0"+dets.reference1number+")<br /><strong>Position: </strong>"+dets.reference1position+"<br /><br />"
            }
            if(dets.reference2 || dets.reference2position || dets.reference2number !=="0"){
                ref2 = "<strong>Reference: </strong>"+dets.reference2+" (0"+dets.reference2number+")<br /><strong>Position: </strong>"+dets.reference2position+"<br /><br />"
            }
            if(dets.employer1 || dets.employer1pos || dets.employer1startmonth || dets.employer1startyear !=="0" || dets.employer1endmonth || dets.employer1endyear !=="0" || dets.employer1reason){
                let emp1end
                if(dets.employer1endmonth && dets.employer1endyear !=="0"){
                    emp1end = "("+dets.employer1endmonth+" / "+dets.employer1endyear+")"
                } else {
                    emp1end = "now"
                }
                emp1 = "<strong>Employer: </strong>"+dets.employer1+" -- ("+dets.employer1startmonth+" / "+dets.employer1startyear+") until "+emp1end+" <br /><strong>Position: </strong>"+dets.employer1pos+"<br /><strong>Reason for Leaving: </strong>"+dets.employer1reason+"<br /><br />"
            }
            if(dets.employer2 || dets.employer2pos || dets.employer2startmonth || dets.employer2startyear !=="0" || dets.employer2endmonth || dets.employer2endyear !=="0" || dets.employer2reason){
                let emp2end
                if(dets.employer2endmonth && dets.employer2endyear !=="0"){
                    emp2end = "("+dets.employer2endmonth+" / "+dets.employer2endyear+")"
                } else {
                    emp2end = "now"
                }
                emp2 = "<strong>Employer: </strong>"+dets.employer2+" -- ("+dets.employer2startmonth+" / "+dets.employer2startyear+") until "+emp2end+" <br /><strong>Position: </strong>"+dets.employer2pos+"<br /><strong>Reason for Leaving: </strong>"+dets.employer2reason+"<br /><br />"
            }
            if(dets.employer3 || dets.employer3pos || dets.employer3startmonth || dets.employer3startyear !=="0" || dets.employer3endmonth || dets.employer3endyear !=="0" || dets.employer3reason){
                let emp3end
                if(dets.employer3endmonth && dets.employer3endyear !=="0"){
                    emp3end = "("+dets.employer3endmonth+" / "+dets.employer3endyear+")"
                } else {
                    emp3end = "now"
                }
                emp3 = "<strong>Employer: </strong>"+dets.employer3+" -- ("+dets.employer3startmonth+" / "+dets.employer3startyear+") until "+emp3end+" <br /><strong>Position: </strong>"+dets.employer3pos+"<br /><strong>Reason for Leaving: </strong>"+dets.employer3reason+"<br /><br />"
            }
            if(dets.criminalselect==="0"){
                crim = "<strong>Criminal offences other than traffic: </strong>No<br /><br />"
            } else {
                crim = "<strong>Criminal offences other than traffic: </strong>Yes<br />Particulars: </strong>"+dets.criminalreason+"<br /><br />"
            }
            if(dets.other){
                other = "<strong>An other information pertinent to this application: </strong>"+dets.other+"<br /><br />"
            }
            if(dets.resume){
                resume = "<a target='_blank' href='https://api.groceryandliquor.co/uploads/"+dets.resume+"'><strong>LINK TO RESUME</strong></a><br /><br />"
            }
            if(dets.coverletter){
                coverletter = "<a target='_blank' href='https://api.groceryandliquor.co/uploads/"+dets.coverletter+"'><strong>LINK TO COVER LETTER</strong></a><br /><br />"
            }
            return (
                <div>
                    <Row className="pt-3 pb-5">
                        <Col>
                            <h2>{dets.firstname} {dets.middlename} {dets.lastname}</h2>
                            <div dangerouslySetInnerHTML={{__html: coverletter}} />
                            <div dangerouslySetInnerHTML={{__html: resume}} />
                            <h5><strong>Can Start Work: </strong>{this.formatDate (dets.startwork)}</h5>
                            <strong>Address: </strong>{dets.address}<br /><br />
                            <strong>Email Address: </strong>{dets.email}<br /><br />
                            <div dangerouslySetInnerHTML={{__html: dob}} />
                            <div dangerouslySetInnerHTML={{__html: age}} />
                            
                            <div dangerouslySetInnerHTML={{__html: phones}} />
                            <div dangerouslySetInnerHTML={{__html: mobile}} />
                            <div dangerouslySetInnerHTML={{__html: homephone}} />

                            <div dangerouslySetInnerHTML={{__html: study}} />

                            <div dangerouslySetInnerHTML={{__html: workerscomp}} />

                            <strong>Monday Availability: </strong>{dets.monday}<br />
                            <strong>Tuesday Availability: </strong>{dets.tuesday}<br />
                            <strong>Wednesday Availability: </strong>{dets.wednesday}<br />
                            <strong>Thursday Availability: </strong>{dets.thursday}<br />
                            <strong>Friday Availability: </strong>{dets.friday}<br />
                            <strong>Saturday Availability: </strong>{dets.saturday}<br />
                            <strong>Sunday Availability: </strong>{dets.sunday}<br /><br />

                            <div dangerouslySetInnerHTML={{__html: qual1}} />
                            <div dangerouslySetInnerHTML={{__html: qual2}} />
                            <div dangerouslySetInnerHTML={{__html: qual3}} />

                            <div dangerouslySetInnerHTML={{__html: emp1}} />
                            <div dangerouslySetInnerHTML={{__html: emp2}} />
                            <div dangerouslySetInnerHTML={{__html: emp3}} />

                            <div dangerouslySetInnerHTML={{__html: ref1}} />
                            <div dangerouslySetInnerHTML={{__html: ref2}} />

                            <strong>Objects to Police Check: </strong>{(dets.policecheck==="0")?"No":"Yes"}<br />
                            
                            <div dangerouslySetInnerHTML={{__html: crim}} />

                            <div dangerouslySetInnerHTML={{__html: other}} />

                        </Col>
                    </Row>
                </div>
            );
        }
        
    }
}

export default ClientIndex;
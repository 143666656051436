import React, { Component } from 'react';
import ProjectLinks from './ProjectLinks';
import { Row, Col, Form, FormGroup, Label, Button, Input, FormFeedback, Spinner } from 'reactstrap';
import axios from 'axios'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            emailValid: "",
            passwordValid: "",
            formValid: false,
            submitting: "",
            submittingSpinner: "d-none",
            loginError: ""
        }
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    validateEmail(){
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(this.state.email)) {
            this.setState({emailValid: "Yes"});
        } else {
            this.setState({emailValid: "No"});
        }
    }
    validatePassword(){
        if (this.state.password !== "") {
            this.setState({passwordValid: "Yes"});
        } else {
            this.setState({passwordValid: "No"});
        }
    }

    submitForm() {
        this.validateEmail();
        this.validatePassword();
        this.setState({ formValid: false, loginError: "" }, function () {
            if(this.state.emailValid === "Yes" && this.state.passwordValid === "Yes"){
                this.setState({
                    formValid: true,
                    submitting: "d-none",
                    submittingSpinner: ""
                });
                const userDetails = {"email": this.state.email, "password": this.state.password};
                axios.post("https://api.retailbs.com.au/login", userDetails)
                .then(res => {
                    if(!res.data.error){
                        localStorage.setItem('token', res.data.access.token);
                        localStorage.setItem('refresh', res.data.access.refresh);
                        this.props.setLoginChild()
                        this.props.history.push('/');
                    } else {
                        this.setState({
                            submitting: "",
                            submittingSpinner: "d-none",
                            loginError: res.data.message
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        formValid: false,
                        submitting: "",
                        submittingSpinner: "d-none",
                        loginError: "An error occured, please try again."
                    });
                })
            } else {
                this.setState({
                    formValid: false,
                    submitting: "",
                    submittingSpinner: "d-none"
                });
            }
        });
    }

    render() {
        return (
            <div>
                <ProjectLinks />
                <Row className="text-center mt-2 mb-5">
                    <Col md="12" className="mt-5 mb-3">
                        <h1 className="text-danger services-h1">Client Login</h1>
                    </Col>
                    <Col md="12" className="mb-5 p-5 text-left services-panel text-dark">
                        <Row>
                            <Col md={{size:8, offset:2 }}>
                                <Form>
                                    <FormGroup>
                                        <Label for="exampleEmail"><strong>Email</strong></Label>
                                        <Input type="email" name="email" id="email" onChange={ (e) => this.setState({email: e.target.value})} placeholder="Email Address" invalid={this.state.emailValid === "No"} valid={this.state.emailValid === "Yes"} />
                                        <FormFeedback>Please enter a correct email address</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="examplePassword"><strong>Password</strong></Label>
                                        <Input type="password" name="password" id="password" onChange={ (e) => this.setState({password: e.target.value})} placeholder="Password" invalid={this.state.passwordValid === "No"} valid={this.state.passwordValid === "Yes"} />
                                        <FormFeedback>Please enter a password</FormFeedback>
                                    </FormGroup>
                                    <div className="text-danger mt-3 mb-3">{this.state.loginError}</div>
                                    <Button className={this.state.submitting} onClick={this.submitForm}>Login</Button>
                                    <div className={this.state.submittingSpinner}><Spinner color="dark" /> Logging in!</div>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Login;
import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, Button, Row, Col, CardText } from 'reactstrap';
import Logo from '../assets/logo.png'
import ProjectLinks from './ProjectLinks';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faPencilAlt, faDesktop, faPrint } from '@fortawesome/free-solid-svg-icons'

class Index extends Component {
    render() {
        return (
            <div>
                <ProjectLinks loggedin={this.props.loggedin} />
                <Card className="index-services-card mt-3">
                        <CardBody className="text-center">
                            <CardTitle>For all your IT, Design &amp; Printing Needs</CardTitle>
                            <CardSubtitle className="mt-3">Have a look at our extensive range of services.</CardSubtitle>
                            <Link to="/services"><Button className="transparent-button btn-lg mt-3">Services</Button></Link>
                        </CardBody>
                    </Card>
                    <Row className="text-center">
                        <Col md="5" className="mt-5">
                            <img alt="RBS Logo" className="img-fluid" src={Logo} />
                        </Col>
                        <Col md="7" className="mt-5">
                            <Card className="index-desc-card">
                                <CardBody className="text-center">
                                    <CardText>
                                        Retail Business Solutions is a small IT Business located in the heart of Sunbury, Victoria. We are determinted &amp; enthusiastic to give you the best outcome when it comes to your IT or Design Projects.<br /><br />Contact us today on 0422 413 834.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="text-center services-panel mt-5 mb-5 text-dark">
                        <Col md="12" className="mt-5 mb-3">
                            <h1 className="text-danger services-h1">Our Services</h1>
                            <h4 className="services-h3">No matter how big or small, we will always try to have a solution for you.</h4>
                        </Col>
                        <Col md="12" className="mb-5 text-left">
                            <Row>
                                <Col md="6" className="pl-5 pr-5 pt-5">
                                <h5><FontAwesomeIcon className="text-danger mr-2" icon={faLocationArrow} />Web Design</h5>
                                <p>
                                Let us create your dream website. You can either provide us with Images to put on your website or we can simply create these for you.
                                </p>
                                </Col>
                                <Col md="6" className="pl-5 pr-5 pt-5">
                                <h5><FontAwesomeIcon className="text-danger mr-2" icon={faDesktop} />IT &amp; Programming</h5>
                                <p>
                                Any IT or Programming needs you may have, we will always have the solution. From Building Computers to the Security, we know Computers inside &amp; out.
                                </p>
                                </Col>
                                <Col md="6" className="pl-5 pr-5 pt-5">
                                <h5><FontAwesomeIcon className="text-danger mr-2" icon={faPencilAlt} />Graphic Design</h5>
                                <p>
                                From Brochures, Catalogues, Posters &amp; Social Media. We can design the perfect item for your needs.
                                </p>
                                </Col>
                                <Col md="6" className="pl-5 pr-5 pt-5">
                                <h5><FontAwesomeIcon className="text-danger mr-2" icon={faPrint} />Printing &amp; Offset Printing</h5>
                                <p>
                                We can print from A5 upwards sized Posters, all the way up to A0 Posters. It's all up to you!
                                </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="text-center mt-3 mb-5 text-light">
                        <Col md="12">
                            <h1 className="text-danger services-h1">Office Hours</h1>
                            <h4 className="services-h3">Monday - Friday : 7am - 5pm (Closed on Public Holidays)</h4>
                            <h4 className="services-h3">80 Evans Steet, Sunbury, Victoria, 3429.</h4>
                        </Col>
                    </Row>
            </div>
        );
    }
}

export default Index;
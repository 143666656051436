import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Header from './Header';
import Routes from './Routes';
import Footer from './Footer';
import ClientHeader from './Client/Header';
import ClientFooter from './Client/Footer';
import axios from 'axios'
import {withRouter} from 'react-router-dom';

class App extends Component {
    constructor(props) {
        super(props);
        this.checkToken = this.checkToken.bind(this);
        this.switchHome = this.switchHome.bind(this);
        this.setLogin = this.setLogin.bind(this);
        this.setLogout = this.setLogout.bind(this);
        this.state = {
            client: false,
            loggedin: false,
        }
    }
    componentDidMount(){
        this.checkToken();
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.checkToken();
        }
    }
    checkToken(){
        let token = localStorage.getItem('token');
        let refresh = localStorage.getItem('refresh');
        if(token){
            axios.post("https://api.retailbs.com.au/checkaccess" , {},
               {
                 headers: {
                   Authorization: 'Bearer ' + token
                 }
               })
            .then(res => {
                if(!res.data.error){
                    this.setState({ loggedin: true})
                } else {
                    localStorage.removeItem('token')
                    this.setState({
                        loggedin: false,
                        client: false
                    })
                }
            })
            .catch(err => {
                localStorage.removeItem('token')
                this.setState({
                    loggedin: false,
                    client: false
                })
            })
        } else {
            if(refresh){
                axios.post("https://api.retailbs.com.au/checkaccessrefresh" , { "refreshtoken": refresh },
               {
                 headers: {
                   Authorization: 'Bearer ' + refresh
                 }
               })
            .then(res => {
                if(!res.data.error){
                    localStorage.setItem('token', res.data.access.token);
                    localStorage.setItem('refresh', res.data.access.refresh);
                    this.setState({ loggedin: true})
                } else {
                    localStorage.clear()
                    this.setState({
                        loggedin: false,
                        client: false
                    })
                }
            })
            .catch(err => {
                localStorage.clear()
                this.setState({
                    loggedin: false,
                    client: false
                })
            })
            } else {
                this.setState({
                    loggedin: false,
                    client: false
                })
            }
        }
    }
    switchHome(){
        this.setState({
            client: !this.state.client,
        });
    }
    setLogin(){
        this.setState({
            loggedin: true,
            client: true,
        });
    }
    setLogout(){
        localStorage.clear()
        this.setState({
            loggedin: false,
            client: false
        });
    }
    render() {
        let clientheader = "";
        let clientfooter = "";
        if(this.state.client && this.state.loggedin){
            clientheader = <ClientHeader switchHome={this.switchHome} client={this.state.client} setLogout={this.setLogout} />
            clientfooter = <ClientFooter />
        } else {
            clientheader = <Header switchHome={this.switchHome} client={this.state.client} loggedin={this.state.loggedin} />
            clientfooter = <Footer />
        }
        return (
            <div>
                <Container>
                    {clientheader}
                    <Routes client={this.state.client} loggedin={this.state.loggedin} setLogin={this.setLogin} />
                    {clientfooter}
                </Container>
            </div>
        );
    }
}

export default withRouter(props => <App {...props}/>);;
import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem
} from 'reactstrap';
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.png';
  

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
    }
    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    render() {
      let clientlogin = ""
        if(!this.props.client && this.props.loggedin){
            clientlogin =
                 <NavItem>
                    <Link onClick={() => this.props.switchHome()} to="/">Client Area</Link>
                  </NavItem>
        } else {
          clientlogin =
            <NavItem>
                    <Link to="/login">Client Login</Link>
                  </NavItem>
        }
        return (
            <div>
            <Navbar color="dark" dark expand="md">
              <NavbarBrand href="/"><img alt="RBS Logo" className="img-fluid" src={Logo} /></NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto mr-auto text-uppercase text-center" navbar>
                  <NavItem>
                    <Link to="/">Home</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/services">Services</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/it">IT</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/design">Design or Print</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/contact">Contact Us</Link>
                  </NavItem>
                  {clientlogin}
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        );
    }
}

export default Header;
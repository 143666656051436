import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem
} from 'reactstrap';
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo.png';
  

class ClientHeader extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
    }
    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    render() {
        return (
            <div>
            <Navbar color="dark" dark expand="md">
              <NavbarBrand href="/"><img alt="RBS Logo" className="img-fluid" src={Logo} /></NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto mr-auto text-uppercase text-center" navbar>
                  <NavItem>
                    <Link onClick={() => this.props.switchHome()} to="/">Home</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/">Applicants</Link>
                  </NavItem>
                  <NavItem>
                    <Link onClick={() => this.props.setLogout()} to="/">Logout</Link>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        );
    }
}

export default ClientHeader;
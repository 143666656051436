import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faGripHorizontal, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

class ProjectLinks extends Component {
    render() {
        let result = ""
        if(this.props.loggedin){
            result =
            <>
            <Col md="6" className="pl-5 pr-5 pt-3">
            <h5 className="project-links"><FontAwesomeIcon className="text-danger mr-2" icon={faUsers} /><Link to="/">Workforce Manager</Link></h5>
            </Col>
            <Col md="6" className="pl-5 pr-5 pt-3">
            <h5 className="project-links"><FontAwesomeIcon className="text-danger mr-2" icon={faGripHorizontal} /><a target="_blank" rel="noopener noreferrer" href="http://dashboard.retailbs.com.au">RBS Dashboard</a></h5>
            </Col>
            </>
        } else {
            result =
            <>
            <Col md="4" className="pl-5 pr-5 pt-3">
            <h5 className="project-links"><FontAwesomeIcon className="text-danger mr-2" icon={faUsers} /><Link to="/login">Workforce Manager</Link></h5>
            </Col>
            <Col md="4" className="pl-5 pr-5 pt-3">
            <h5 className="project-links"><FontAwesomeIcon className="text-danger mr-2" icon={faGripHorizontal} /><a target="_blank" rel="noopener noreferrer" href="http://dashboard.retailbs.com.au">RBS Dashboard</a></h5>
            </Col>
            <Col md="4" className="pl-5 pr-5 pt-3">
            <h5 className="project-links"><FontAwesomeIcon className="text-danger mr-2" icon={faSignInAlt} /><Link to="/login">Client Login</Link></h5>
            </Col>
            </>
        }
        return (
            <Row className="text-center services-panel mt-3 text-dark">
                <Col md="12" className="mb-3">
                    <Row>
                        {result}
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default ProjectLinks;
import React, { Component } from 'react';
import ProjectLinks from './ProjectLinks';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faPencilAlt, faLocationArrow } from '@fortawesome/free-solid-svg-icons'

class Design extends Component {
    render() {
        return (
            <div>
                <ProjectLinks loggedin={this.props.loggedin} />
                <Row className="text-center mt-2 mb-5">
                    <Col md="12" className="mt-5 mb-5">
                        <h1 className="text-danger services-h1">Graphic Design, Web Design &amp; Printing</h1>
                        <h4 className="services-h3">Whether it's designing a logo to printing a poster, we can help you.</h4>
                    </Col>
                    <Col md="12" className="pb-5 text-center services-panel text-dark">
                        <Row>
                            <Col md="12" className="pl-5 pr-5 pt-5">
                            <h3><FontAwesomeIcon className="text-danger mr-2" icon={faPrint} />Printing</h3>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="6" className="print-box p-3">
                                <h5 className="mt-2"><strong>Black &amp; White Print</strong></h5>
                                <p className="mt-2">
                                    <strong>A2</strong> (420mm x 594mm) - <strong>$7 each</strong><br />
                                    <strong>A1</strong> (594mm x 841) - <strong>$9 each</strong><br />
                                    <strong>A0</strong> (841mm x 1189mm) - <strong>$12.50 each</strong><br />
                                </p>
                            </Col>
                            <Col md="6" className="print-box p-3">
                                <h5 className="mt-2"><strong>Colour Print</strong></h5>
                                <p className="mt-2">
                                    <strong>A2</strong> (420mm x 594mm) - <strong>$10 each</strong><br />
                                    <strong>A1</strong> (594mm x 841) - <strong>$15 each</strong><br />
                                    <strong>A0</strong> (841mm x 1189mm) - <strong>$20 each</strong><br />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="pl-5 pr-5 pt-5">
                            <h3><FontAwesomeIcon className="text-danger mr-2" icon={faPencilAlt} />Graphic Design</h3>
                            <h4 className="services-h3">$30 an hour for all small design work.</h4>
                            <h5 className="services-h3">Custom quotes available for larger projects.</h5><h5 className="services-h3">Contact us on 0422413834 for further details.</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="pl-5 pr-5 pt-5">
                            <h3><FontAwesomeIcon className="text-danger mr-2" icon={faLocationArrow} />Web Design</h3>
                            <h5 className="services-h3">Contact us on 0422413834 for a tailored quote.</h5>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Design;
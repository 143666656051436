import React, { Component }  from 'react';
import {Route, Switch} from 'react-router-dom';

import Index from './Index';
import Services from './Services';
import IT from './IT';
import Design from './Design';
import Contact from './Contact';
import Login from './Login';
import NotFound from './NotFound';

import ClientIndex from './Client/Index';
import ViewApplicant from './Client/ViewApplicant';

class Routes extends Component {
    constructor(props) {
        super(props);
        this.setLoginChild = this.setLoginChild.bind(this);
    }
    setLoginChild(){
        this.props.setLogin()
    }
    render(){
        let loggedin = this.props.loggedin
        let client = this.props.client
        let clientroute = ""
        if(client && loggedin){
            clientroute = <>
                <Route exact path="/" component={ClientIndex}/>
                <Route exact path="/client/viewapplicant" component={ViewApplicant}/>
                </>
        } else {
            clientroute = <>
                <Route exact path="/" render={(props) => <Index {...props} loggedin={loggedin} />}/>
                <Route exact path="/services" render={(props) => <Services {...props} loggedin={loggedin} />}/>
                <Route exact path="/it" render={(props) => <IT {...props} loggedin={loggedin} />}/>
                <Route exact path="/design" render={(props) => <Design {...props} loggedin={loggedin} />}/>
                <Route exact path="/contact" render={(props) => <Contact {...props} loggedin={loggedin} />} />
                <Route exact path="/login" render={(props) => <Login {...props} setLoginChild={this.setLoginChild} loggedin={loggedin} />} />
                </>
        }
        return(
            <Switch>
                {clientroute}
                <Route path="*" component={NotFound}/>
            </Switch>
        )}
}
export default Routes;
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class ClientFooter extends Component {
    render() {
        return (
            <div>
                <Row className="text-center mt-3 mb-1 text-light">
                    <Col md="12">
                        <h6>&copy; 2019 - Retail Business Solutions</h6>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ClientFooter;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, Spinner, ListGroup, ListGroupItem } from 'reactstrap';

class ClientIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applicants: [],
        }
    }
    componentDidMount(){
        axios.post("https://api.groceryandliquor.co/applicants/getall",{retailbs: "yes"})
        .then(res => {
            this.setState({
                applicants: res.data
            })
        })
        .catch(err => {
            //error checking
            console.error(err); 
        })
    }
    render() {
        if(this.state.applicants.length === 0){
            return(<div><Spinner color="light" /> Loading Applicants!</div>);
        } else {
            let applicants = this.state.applicants.map((app, index) =>
            <Link key={index} to={{ pathname: '/client/viewapplicant', applicantid: app.applicant_id }}><ListGroupItem color="dark" ><strong>{app.applicant_name}</strong><br /><small>{app.job_name} - Applied @ {app.date_submitted}</small></ListGroupItem></Link>
            )
            return (
                <div>
                    <Row className="pt-3">
                        <Col>
                            <ListGroup>
                                {applicants}
                            </ListGroup>
                        </Col>
                    </Row>
                </div>
            );
        }
        
    }
}

export default ClientIndex;
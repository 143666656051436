import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ProjectLinks from './ProjectLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faPencilAlt, faDesktop, faPrint } from '@fortawesome/free-solid-svg-icons'

class Services extends Component {
    render() {
        return (
            <div>
                <ProjectLinks loggedin={this.props.loggedin} />
                <Row className="text-center mt-2 mb-5">
                    <Col md="12" className="mt-5 mb-3">
                        <h1 className="text-danger services-h1">Our Services</h1>
                        <h4 className="services-h3">No matter how big or small, we will always try to have a solution for you.</h4>
                    </Col>
                    <Col md="12" className="mb-5 pb-5 text-left services-panel text-dark">
                        <Row>
                            <Col md="6" className="pl-5 pr-5 pt-5">
                            <h5><FontAwesomeIcon className="text-danger mr-2" icon={faLocationArrow} />Web Design</h5>
                            <p>
                            Let us create your dream website. You can either provide us with Images to put on your website or we can simply create these for you.
                            </p>
                            </Col>
                            <Col md="6" className="pl-5 pr-5 pt-5">
                            <h5><FontAwesomeIcon className="text-danger mr-2" icon={faDesktop} />IT &amp; Programming</h5>
                            <p>
                            Any IT or Programming needs you may have, we will always have the solution. From Building Computers to the Security, we know Computers inside &amp; out.
                            </p>
                            </Col>
                            <Col md="6" className="pl-5 pr-5 pt-5">
                            <h5><FontAwesomeIcon className="text-danger mr-2" icon={faPencilAlt} />Graphic Design</h5>
                            <p>
                            From Brochures, Catalogues, Posters &amp; Social Media. We can design the perfect item for your needs.
                            </p>
                            </Col>
                            <Col md="6" className="pl-5 pr-5 pt-5">
                            <h5><FontAwesomeIcon className="text-danger mr-2" icon={faPrint} />Printing &amp; Offset Printing</h5>
                            <p>
                            We can print from A5 upwards sized Posters, all the way up to A0 Posters. It's all up to you!
                            </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Services;
import React, { Component } from 'react';
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

class Footer extends Component {
    render() {
        return (
            <div>
                <Row className="mt-3 mb-5 text-light">
                        <Col md="3" xs="4">
                            <h5 className="text-danger services-h1">Services</h5>
                            <ListGroup className="bottom-list">
                                <ListGroupItem><FontAwesomeIcon className="text-danger mr-2" icon={faAngleRight} /><Link to="/it">IT</Link></ListGroupItem>
                                <ListGroupItem><FontAwesomeIcon className="text-danger mr-2" icon={faAngleRight} /><Link to="/design">Design</Link></ListGroupItem>
                                <ListGroupItem><FontAwesomeIcon className="text-danger mr-2" icon={faAngleRight} /><Link to="/services">Our Services</Link></ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col md="3" xs="4">
                            <h5 className="text-danger services-h1">Support</h5>
                            <ListGroup className="bottom-list">
                                <ListGroupItem><FontAwesomeIcon className="text-danger mr-2" icon={faAngleRight} /><a target="_blank" rel="noopener noreferrer" href="https://anydesk.com">Remote Support</a></ListGroupItem>
                                <ListGroupItem><FontAwesomeIcon className="text-danger mr-2" icon={faAngleRight} /><Link to="/contact">Contact Us</Link></ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col md="3" xs="4">
                            <h5 className="text-danger services-h1">Solutions</h5>
                            <ListGroup className="bottom-list">
                                <ListGroupItem><FontAwesomeIcon className="text-danger mr-2" icon={faAngleRight} /><Link to="/login">Workforce Manager</Link></ListGroupItem>
                                <ListGroupItem><FontAwesomeIcon className="text-danger mr-2" icon={faAngleRight} /><a target="_blank" rel="noopener noreferrer" href="http://dashboard.retailbs.com.au">RBS Dashboard</a></ListGroupItem>
                                <ListGroupItem><FontAwesomeIcon className="text-danger mr-2" icon={faAngleRight} /><Link to="/login">Client Login</Link></ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col md="3" className="text-center pt-2">
                            <img alt="RBS Logo" className="img-fluid bottom-logo" src={Logo} />
                        </Col>
                    </Row>
                    <Row className="text-center mt-3 mb-1 text-light">
                        <Col md="12">
                            <h6>&copy; 2019 - Retail Business Solutions</h6>
                        </Col>
                    </Row>
            </div>
        );
    }
}

export default Footer;